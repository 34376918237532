import React, { useEffect, useRef, useState } from 'react'
import "./TrabalheConosco.css"
import colaboradores from "../assets/colaboradorSelsyn.svg"
import quadroColaboradores from "../assets/QuadradosInformacao.svg"
import cracha from "../assets/cracha.svg"
import quadroColaboradoresResponsivo from "../assets/porqueTrabalharResponsivo.svg"

const TrabalheConosco = () => {


    const [nome, setNome] = useState("");
    const [telefone, setTelefone] = useState("");
    const [email, setEmail] = useState("");
    const [arquivo, setArquivo] = useState(null);
    const [mensagem, setMensagem] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const verificarInputs = () => {
        const nomeInput = document.getElementById("nome");
        const telefoneInput = document.getElementById("telefone");
        const emailInput = document.getElementById("email");
        const mensagemInput = document.getElementById("mensagem");

        let nomeLabel = document.getElementById("nomeLabel");
        let telefoneLabel = document.getElementById("telefoneLabel");
        let emailLabel = document.getElementById("emailLabel");
        let mensagemLabel = document.getElementById("mensagemLabel");

        if (nomeInput.value.trim() === "") {
            nomeInput.classList.add("border-solid", "border-2", "border-red-500");
            if (!nomeLabel) {
                nomeLabel = document.createElement("label");
                nomeLabel.setAttribute("id", "nomeLabel");
                nomeLabel.setAttribute("for", "nome");
                nomeLabel.classList.add("text-red-500", "block", "mb-2");
                nomeLabel.innerText = "Campo Obrigatório*";
                nomeInput.parentNode.insertBefore(nomeLabel, nomeInput);
            }
        } else {
            nomeInput.classList.remove("border-solid", "border-2", "border-red-500");
            if (nomeLabel) nomeLabel.remove();
        }

        if (telefoneInput.value.trim() === "") {
            telefoneInput.classList.add("border-solid", "border-2", "border-red-500");
            if (!telefoneLabel) {
                telefoneLabel = document.createElement("label");
                telefoneLabel.setAttribute("id", "telefoneLabel");
                telefoneLabel.setAttribute("for", "telefone");
                telefoneLabel.classList.add("text-red-500", "block", "mb-2");
                telefoneLabel.innerText = "Campo Obrigatório*";
                telefoneInput.parentNode.insertBefore(telefoneLabel, telefoneInput);
            }
        } else {
            telefoneInput.classList.remove(
                "border-solid",
                "border-2",
                "border-red-500"
            );
            if (telefoneLabel) telefoneLabel.remove();
        }

        if (emailInput.value.trim() === "") {
            emailInput.classList.add("border-solid", "border-2", "border-red-500");
            if (!emailLabel) {
                emailLabel = document.createElement("label");
                emailLabel.setAttribute("id", "emailLabel");
                emailLabel.setAttribute("for", "email");
                emailLabel.classList.add("text-red-500", "block", "mb-2");
                emailLabel.innerText = "Campo Obrigatório*";
                emailInput.parentNode.insertBefore(emailLabel, emailInput);
            }
        } else {
            emailInput.classList.remove("border-solid", "border-2", "border-red-500");
            if (emailLabel) emailLabel.remove();
        }

        if (mensagemInput.value.trim() === "") {
            mensagemInput.classList.add("border-solid", "border-2", "border-red-500");
            if (!mensagemLabel) {
                mensagemLabel = document.createElement("label");
                mensagemLabel.setAttribute("id", "mensagemLabel");
                mensagemLabel.setAttribute("for", "mensagem");
                mensagemLabel.classList.add("text-red-500", "block", "mb-2");
                mensagemLabel.innerText = "Campo Obrigatório*";
                mensagemInput.parentNode.insertBefore(mensagemLabel, mensagemInput);
            }
        } else {
            mensagemInput.classList.remove(
                "border-solid",
                "border-2",
                "border-red-500"
            );
            if (mensagemLabel) mensagemLabel.remove();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        verificarInputs();
        const form = e.target;
        const formData = new FormData(form);

        try {
            const response = await fetch("envioCurriculo.php", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                setModalMessage(
                    "Currículo enviado com sucesso! Em breve, nossa equipe entrará em contato com você."
                );
            } else {
                setModalMessage("Erro, tente novamente");

            }
        } catch (error) {
            console.error(error);
            setModalMessage("Erro, tente novamente");
        } finally {
            setModalVisible(true);
        }


    };

    const formRef = useRef(null);
    const [showForm, setShowForm] = useState(false);

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };
    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setShowForm(false);
        }

    };

    useEffect(() => {
        if (showForm) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showForm]);
    return (
        <div className='backgroundTrabalhe'>
            <div className=" mx-0 z-0 my-10 h-5/12 md:z-0 flex justify-center  md:mx-20 md:mt-40 ">
                <div className=" md:ml-24 p-6 w-full">
                    <span className='flex justify-center text-base md:justify-normal md:text-xl'>Se torne agora um</span>
                    <h1 className="text-center text-gradient text-4xl md:text-left md:text-6xl fontBebasNeue ">
                        COLABORADOR SELSYN
                    </h1>
                    <span className='flex justify-center text-base md:justify-normal md:text-xl'>e faça parte da nossa equipe</span>
                    <div className="flex justify-center md:justify-start">
                        <button
                            onClick={handleButtonClick}
                            className="text-gradientButton md:text-gradientButton font-bold mt-6 px-8 py-4 rounded-full hover:scale-110 duration-500"
                        >
                            QUERO FAZER PARTE
                        </button>
                    </div>
                </div>
                <div className=" hidden md:flex justify-center w-full">
                    <img className="w-4/6" src={colaboradores} alt="MacBook" />
                </div>
            </div>

            <div>
                <h1 className=" pt-1 md:pt-16 font-bold text-2xl md:text-4xl text-gradient text-center mb-5 md:mb-16">
                    POR QUE TRABALHAR CONOSCO?
                </h1>
                <div className="hidden mx-auto md:block w-8/12 my-10">
                    <img src={quadroColaboradores} className="w-full" alt='' />
                </div>
                <div className="block mx-auto md:hidden w-11/12 pt-4">
                    <img src={quadroColaboradoresResponsivo} className="w-full" alt='' />
                </div>
            </div>


            <div className='flex justify-center items-center my-5 md:my-20'>
                <div className="hidden md:block ">
                    <img src={cracha} className="w-full" alt='' />
                </div>
                <div className='flex flex-col items-center md:items-start'>
                    <h1 className="flex justify-center md:justify-start pt-6 md:pt-16 font-bold text-2xl md:text-3xl text-gradient mb-4 text-center md:text-left">
                        QUEREMOS TE CONHECER!
                    </h1>
                    <span className='flex justify-center md:justify-start text-lg text-center md:text-left md:w-8/12'>
                        Interessado em fazer parte da nossa equipe? Envie seu currículo
                    </span>
                    <button
                        onClick={handleButtonClick}
                        className="text-gradientButton mt-4 px-2 font-semibold w-56 py-3 rounded-full text-center"
                    >
                        QUERO FAZER PARTE
                    </button>
                </div>

            </div>


            {showForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div
                        ref={formRef}
                        className="bg-white rounded-lg shadow-lg p-8 md:w-1/2 w-full"
                    >
                        <h1 className="text-center text-3xl font-bold text-gradient mb-4">
                            ENVIE SEU CURRÍCULO!
                        </h1>
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <input
                                className="shadow-md border border-gray-300 my-2 py-2 px-4 rounded-full w-80 md:w-full"
                                type="text"
                                id="nome"
                                placeholder="Seu nome"
                                name="nome"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                            <input
                                className="shadow-md border border-gray-300 my-2 py-2 px-4 rounded-full w-80 md:w-full"
                                type="number"
                                id="telefone"
                                name="telefone"
                                placeholder="Seu telefone"
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                            />
                            <input
                                className="shadow-md border border-gray-300 my-2 py-2 px-4 rounded-full w-80 md:w-full"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Seu e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <textarea
                                className="h-28 shadow-md border border-gray-300 my-2 py-4 px-4 rounded-2xl w-80 md:w-full"
                                id="mensagem"
                                name="mensagem"
                                placeholder="Sua mensagem"
                                value={mensagem}
                                onChange={(e) => setMensagem(e.target.value)}
                            ></textarea>

                            <label
                                htmlFor="arquivo"
                                className="shadow-md border border-gray-300 my-2 py-2 px-4 rounded-full w-80 md:w-full text-left cursor-pointer"
                            >
                                {arquivo ? arquivo.name : "Anexo (Currículo)"}
                            </label>
                            <input
                                id="arquivo"
                                name="arquivo"
                                type="file"
                                className="hidden"
                                onChange={(e) => {
                                    setArquivo(e.target.files[0]);
                                }}
                            />
                            {arquivo && (
                                <p className="mt-2 text-sm text-gray-600">
                                    Arquivo selecionado: {arquivo.name}
                                </p>
                            )}
                            {modalVisible && (
                                <div className="inset-0 flex items-center text-center justify-center">
                                    <div className=" p-6 rounded">

                                        <p className="font-bold">{modalMessage}</p>
                                    </div>
                                </div>
                            )}
                            <button
                                className="text-gradientButton text-white font-bold py-2 px-6 rounded-full mt-4"
                                type="submit"
                            >
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TrabalheConosco